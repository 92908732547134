* {
  box-sizing: border-box;
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
}

:root {
  --text-light: #211f1f;
  --bg-light: #f2f3f4;
  --text-dark: ivory;
  --bg-dark: #2c2a2a;
  --inverted-light: #211f1f;
  --inverted-dark: #f2f3f4;
  --brandcolor1: #ba7340;
  --brandcolor2: #446782;
  --brandcolor3: #f5c654;
  font-weight: 400;
}

@media (prefers-color-scheme: light) {
  :root {
    --text: var(--text-light);
    --bg: var(--bg-light);
    --inverted: var(--inverted-light);
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    --text: var(--text-dark);
    --bg: var(--bg-dark);
    --inverted: var(--inverted-dark);
  }

  .footer .row svg {
    filter: invert();
  }
}

[data-theme="dark"] {
  --text: var(--text-dark);
  --bg: var(--bg-dark);
  --inverted: var(--inverted-dark);
}

[data-theme="light"] {
  --text: var(--text-light);
  --bg: var(--bg-light);
  --inverted: var(--inverted-light);
}

.theme-switch-wrapper {
  align-items: center;
  display: flex;
  position: absolute;
}

.theme-switch {
  height: 34px;
  width: 60px;
  display: inline-block;
  position: relative;
}

.theme-switch input {
  display: none;
}

.slider {
  background-color: var(--brandcolor1);
  cursor: pointer;
  transition: all .4s;
  position: absolute;
  inset: 0;
  transform: scale(.6);
}

.slider:before {
  opacity: .9;
  content: "";
  height: 26px;
  width: 26px;
  background-color: #fff;
  transition: all .4s;
  position: absolute;
  bottom: 4px;
  left: 4px;
}

input:checked + .slider {
  background-color: var(--brandcolor2);
}

input:checked + .slider:before {
  transform: translateX(26px);
}

@media (min-width: 320px) {
  .theme-switch-wrapper {
    bottom: 51px;
    left: 105px;
  }

  .slider {
    transform: scale(.4);
  }
}

@media (min-width: 480px) {
  .theme-switch-wrapper {
    bottom: 78px;
    left: 125px;
  }

  .slider {
    transform: scale(.4);
  }
}

@media (min-width: 768px) {
  .theme-switch-wrapper {
    bottom: 78px;
    left: 148px;
  }

  .slider {
    transform: scale(.4);
  }
}

@media (min-width: 992px) {
  .theme-switch-wrapper {
    bottom: 79px;
    left: 135px;
  }

  .slider {
    transform: scale(.5);
  }
}

@media (min-width: 1200px) {
  .theme-switch-wrapper {
    bottom: 80px;
    left: 150px;
  }

  .slider {
    transform: scale(.5);
  }
}

@media (min-width: 1400px) {
  .theme-switch-wrapper {
    bottom: 77px;
    left: 225px;
  }

  .slider {
    transform: scale(.5);
  }
}

body {
  background-color: var(--bg);
}

.section, section {
  max-width: 100vw;
  height: 100%;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.mask-svg {
  width: 150px;
  height: 115px;
  fill: var(--bg);
  z-index: 19;
  position: absolute;
  top: -20px;
  left: -60px;
}

.divider {
  padding-top: 5rem;
}

h1 {
  color: var(--text);
  text-align: center;
  font-family: Alata, sans-serif;
}

h2 {
  color: var(--text);
  font-family: Alata, sans-serif;
}

h3 {
  color: var(--text);
  text-transform: uppercase;
  font-family: Alata, sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  display: block;
}

h4 {
  color: #5a5a5a;
  text-transform: uppercase;
  padding-top: .5rem;
  font-family: Alata, sans-serif;
  font-size: 2.7rem;
}

ul {
  padding: 0;
  list-style: none;
}

a {
  color: #d4a628;
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  cursor: pointer;
  background: #2e2e2e;
}

.logo {
  max-width: 100%;
  height: auto;
  width: auto\9 ;
  margin-top: .5rem;
  display: block;
  position: absolute;
  top: 15px;
  left: 15px;
}

.header {
  width: 100%;
  max-width: 100%;
  box-shadow: none;
  z-index: 20;
  margin: 0 auto;
  display: flex;
  position: fixed;
  overflow: hidden;
  height: 90px !important;
}

.main {
  height: 100%;
  margin: 60px auto 0;
  display: block;
}

.mainInner {
  height: 100%;
  width: 100%;
  text-align: center;
  display: table;
}

.mainInner div {
  vertical-align: middle;
  letter-spacing: 1.25px;
  font-size: 3em;
  font-weight: bolder;
  display: table-cell;
}

#sidebarMenu {
  height: 100%;
  width: 250px;
  background: var(--bg);
  z-index: 998;
  margin-top: 0;
  transition: transform .2s ease-in-out;
  position: fixed;
  right: 0;
  transform: translateX(300px);
}

.sidebarMenuInner {
  margin: 0;
  padding: 0;
}

.sidebarMenuInner li {
  color: #2c2c2c;
  text-transform: uppercase;
  cursor: pointer;
  padding: 20px;
  font-weight: bold;
  list-style: none;
}

.sidebarMenuInner li span {
  color: #ffffff80;
  font-size: 14px;
  display: block;
}

.sidebarMenuInner li a {
  color: var(--text);
  text-transform: uppercase;
  cursor: pointer;
  font-family: Alata, sans-serif;
  font-weight: 400;
  text-decoration: none;
}

input[type="checkbox"]:checked ~ #sidebarMenu {
  transform: translateX(0);
}

input[type="checkbox"] {
  box-sizing: border-box;
  transition: all .3s;
  display: none;
}

.sidebarIconToggle {
  box-sizing: border-box;
  cursor: pointer;
  z-index: 999;
  height: 100%;
  width: 100%;
  height: 22px;
  width: 22px;
  transition: all .3s;
  position: fixed;
  top: 38px;
  right: 30px;
}

.spinner {
  box-sizing: border-box;
  height: 3px;
  width: 100%;
  background-color: var(--text);
  transition: all .3s;
  position: absolute;
}

.horizontal {
  box-sizing: border-box;
  float: left;
  margin-top: 3px;
  transition: all .3s;
  position: relative;
}

.diagonal.part-1 {
  box-sizing: border-box;
  float: left;
  transition: all .3s;
  position: relative;
}

.diagonal.part-2 {
  box-sizing: border-box;
  float: left;
  margin-top: 3px;
  transition: all .3s;
  position: relative;
}

input[type="checkbox"]:checked ~ .sidebarIconToggle > .horizontal {
  box-sizing: border-box;
  opacity: 0;
  transition: all .3s;
}

input[type="checkbox"]:checked ~ .sidebarIconToggle > .diagonal.part-1 {
  box-sizing: border-box;
  margin-top: 8px;
  transition: all .3s;
  transform: rotate(135deg);
}

input[type="checkbox"]:checked ~ .sidebarIconToggle > .diagonal.part-2 {
  box-sizing: border-box;
  margin-top: -9px;
  transition: all .3s;
  transform: rotate(-135deg);
}

.nav-contactinfo {
  padding-top: 2rem;
  padding-left: 1.3rem;
  font-size: 1.1rem;
  font-weight: 400;
}

.nav-contactinfo a {
  color: var(--text);
}

.nav-contactinfo li {
  padding-top: .7rem;
}

.footer-social-icons {
  width: 350px;
  margin: 0 auto;
  display: block;
}

ul.social-icons {
  margin-top: 25px;
  padding-left: 20px;
}

.social-icons li {
  vertical-align: top;
  height: 100px;
  display: inline;
}

.social-icons svg {
  fill: var(--inverted);
  width: 35px;
  height: 35px;
}

.social-icons svg:hover {
  transition: all .5s;
}

.svg-facebook:hover {
  fill: var(--brandcolor1);
}

.svg-twitter:hover {
  fill: var(--brandcolor2);
}

.svg-youtube:hover {
  fill: var(--brandcolor3);
}

.home {
  max-width: 100vw;
  max-height: 100vh;
  opacity: 1;
  filter: brightness(90%);
  background-image: url("bg1-2.735b9675.webp");
  background-position: 32%;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 400px) {
  .home {
    background-image: url("bg1-4.4022e377.webp");
  }
}

.about-image {
  background-image: url("bg-about.57539013.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.services {
  opacity: 1;
  background-position: 40%;
  background-repeat: no-repeat;
  background-size: cover;
}

.process {
  opacity: 1;
}

.gallery {
  opacity: 1;
  background-position: 60%;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 800px) {
  .contact {
    background-position: 53%;
    background-size: 150%;
  }
}

.fullscreen-container {
  height: 100vh;
  width: 100vw;
  position: relative;
}

.main-container {
  height: 100vh;
  width: 100vw;
  z-index: 990;
  flex-direction: column;
  flex-shrink: 2;
  justify-content: left;
  padding-bottom: 7vmin;
  display: flex;
  position: absolute;
}

.main-title {
  color: var(--text-dark);
  letter-spacing: .1rem;
  text-transform: uppercase;
  max-width: 420px;
  font-family: Alata, sans-serif;
  font-weight: 300;
  display: flex;
  position: absolute;
}

.secondary-title {
  color: var(--text-dark);
  text-transform: uppercase;
  margin-left: 6px;
  font-family: Alata, sans-serif;
  font-weight: 600;
  position: absolute;
}

.paragraph-title {
  align-content: center;
  display: flex;
}

.paragraph-title-center {
  place-content: center;
  display: flex;
}

.paragraph-title h3 {
  color: var(--text);
  border: 2px solid var(--text);
  padding: 4px;
  font-size: 1rem;
  font-weight: 400;
}

.paragraph-title-center h3 {
  color: var(--text);
  border: 2px solid var(--inverted);
  padding: 4px;
  font-weight: 400;
}

.paragraph-header {
  color: var(--text);
  max-width: 70%;
  padding-top: 3rem;
  font-family: Alata, sans-serif;
  font-weight: 500;
}

.paragraph {
  color: var(--text);
  padding-top: 40px;
  font-family: Alata, sans-serif;
  position: relative;
}

.paragraph p {
  opacity: 1;
  font-weight: 400;
}

@media (min-width: 320px) {
  .main-container {
    margin-left: 1rem;
    padding-top: 35vmin;
  }

  .main-title {
    max-width: 20px;
    font-size: 2.4rem;
    font-weight: 600;
    line-height: 2.5rem;
    bottom: 3.5rem;
  }

  .secondary-title {
    max-width: 90vw;
    font-size: .8rem;
    line-height: 1.5rem;
    bottom: 2.1rem;
  }

  .paragraph-header {
    max-width: 80vw;
    padding-top: 1.6rem;
    font-size: 1.3rem;
    line-height: 2rem;
  }

  .paragraph {
    padding-top: 30px;
    font-size: 1rem;
    line-height: 1.3rem;
  }
}

@media (max-width: 480px) {
  .quote-section {
    display: none;
  }
}

@media (min-width: 480px) {
  .main-container {
    margin-left: 1rem;
    padding-top: 25vmin;
  }

  .main-title {
    max-width: 20px;
    font-size: 2.8rem;
    line-height: 2.5rem;
    bottom: 5rem;
  }

  .secondary-title {
    max-width: 90vw;
    font-size: .8rem;
    line-height: 1.5rem;
    bottom: 3.5rem;
  }

  .paragraph-header {
    max-width: 78vw;
    padding-top: 2rem;
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .paragraph {
    padding-top: 30px;
    font-size: 1rem;
    line-height: 1.3rem;
  }
}

@media (min-width: 768px) {
  .main-container {
    margin-left: 4.2rem;
    padding-top: 15vmin;
  }

  .main-title {
    font-size: 3.2rem;
    line-height: 3rem;
    bottom: 5rem;
  }

  .secondary-title {
    max-width: 90vw;
    font-size: .9rem;
    line-height: 1.5rem;
    bottom: 3.5rem;
  }

  .paragraph-header {
    max-width: 75vw;
    padding-top: 2.6rem;
    font-size: 1.6rem;
    line-height: 2rem;
  }

  .paragraph {
    padding-top: 30px;
    font-size: 1.1rem;
    line-height: 1.3rem;
  }
}

@media (min-width: 992px) {
  .main-container {
    margin-left: 4.5rem;
    padding-top: 10vmin;
  }

  .main-title {
    font-size: 3rem;
    line-height: 3rem;
    bottom: 5rem;
  }

  .secondary-title {
    bottom: 3.5rem;
  }

  .paragraph-header {
    max-width: 55vw;
    padding-top: 1.6rem;
    font-size: 1.8rem;
    line-height: 2.2rem;
  }

  .paragraph {
    padding-top: 25px;
    font-size: 1.1rem;
    line-height: 1.6rem;
    position: relative;
  }
}

@media (min-width: 1200px) {
  .main-container {
    max-width: 100vw;
    margin-left: 4.5rem;
    padding-top: 16vmin;
  }

  .main-title {
    font-size: 3.3rem;
    line-height: 3.2rem;
    bottom: 5rem;
  }

  .secondary-title {
    font-size: 1.1rem;
    bottom: 3.5rem;
  }

  .paragraph {
    padding-top: 1.3rem;
    font-size: 1.1rem;
    line-height: 1.5rem;
    position: relative;
  }

  .paragraph-header {
    max-width: 60vw;
    padding-top: 1.9rem;
    font-size: 1.8rem;
    line-height: 2.2rem;
  }
}

@media (min-width: 1400px) {
  .main-container {
    max-width: 100vw;
    margin-left: 6rem;
    padding-top: 16vmin;
  }

  .main-title {
    font-size: 4.9rem;
    line-height: 4.2rem;
    bottom: 5rem;
  }

  .secondary-title {
    font-size: 1.1rem;
    bottom: 3.5rem;
  }

  .paragraph {
    padding-top: 2rem;
    font-size: 1.1rem;
    line-height: 1.5rem;
    position: relative;
  }

  .paragraph-header {
    max-width: 60vw;
    padding-top: 2rem;
    font-size: 2.5rem;
    line-height: 3rem;
  }
}

.about-container {
  height: 100%;
  place-content: center;
  font-family: Alata, sans-serif;
  display: flex;
}

.about-description {
  width: 50%;
  min-height: 500px;
  padding-left: 4.7vw;
  padding-right: 4.7vw;
}

.about-image {
  width: 50%;
  height: auto;
  min-height: 500px;
}

@media screen and (max-width: 800px) {
  .about-container {
    flex-direction: column;
  }

  .about-description, .about-image {
    width: 100vw;
    min-height: 30vh;
  }
}

@media (min-width: 320px) {
  .about-description {
    padding-top: 5rem;
    padding-bottom: 8rem;
  }
}

@media (min-width: 480px) {
  .about-description {
    padding-bottom: 8rem;
  }
}

@media (min-width: 768px) {
  .about-description {
    padding-bottom: 8rem;
  }
}

@media (min-width: 1200px) {
  .about-description {
    padding: 6em;
  }
}

@media (min-width: 1400px) {
  .about-description {
    padding: 6em;
  }
}

.coverage {
  grid-gap: 20px;
  text-align: center;
  grid-template-columns: repeat(12, 1fr);
  justify-content: space-evenly;
  padding-top: 2rem;
  display: grid;
}

.location {
  border: 2px solid var(--inverted);
  grid-column: auto / span 4;
  justify-content: center;
  align-items: center;
  padding: 20px;
  display: flex;
}

.location h5 {
  font-size: .8rem;
}

.service-description {
  height: 40em;
}

.services-grid {
  flex-direction: row;
  flex-grow: 1;
  display: flex;
}

.service-container {
  flex: 1;
}

.col-left {
  order: -1;
}

.service-description {
  justify-content: center;
  align-items: center;
  display: flex;
}

.service-container h4 {
  text-transform: uppercase;
  color: var(--text);
  padding-bottom: 1rem;
  font-family: Alata, sans-serif;
  font-size: 2.3rem;
  font-weight: 500;
  line-height: 2.3rem;
}

.service-container p {
  color: var(--text);
  opacity: .9;
  font-family: Alata, sans-serif;
  font-size: 1.1rem;
  line-height: 1.5rem;
}

.Aligner-item {
  width: 80%;
}

.services-image {
  background-position: center;
  background-size: cover;
}

@media (max-width: 640px) {
  .services-grid {
    height: 40em;
    flex-direction: column-reverse;
  }

  .service-container {
    order: vertical;
  }

  .col-left {
    order: 0;
  }

  .service-description div p {
    padding: 1em;
  }

  .Aligner-item {
    width: 90%;
  }

  .service-container h4 {
    font-size: 1.4rem;
  }
}

@media (min-width: 320px) {
  .service-container {
    height: 70vh;
  }
}

@media (min-width: 480px) {
  .service-container {
    height: 70vh;
  }
}

@media (min-width: 768px) {
  .service-container {
    height: 50vh;
  }
}

@media (min-width: 992px) {
  .service-container {
    height: 50vh;
  }
}

@media (min-width: 1200px) {
  .service-container {
    height: 50vh;
  }
}

@media (min-width: 1400px) {
  .service-container {
    height: 50vh;
  }
}

.testimonial-heading {
  letter-spacing: 1px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
  padding: 10px 20px;
  display: flex;
}

.testimonial-heading span {
  color: var(--text);
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-size: 1.3rem;
}

.testimonial-box-container {
  border: 10px solid var(--bg);
  width: 100vw;
  background-color: #e4e0dd;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: baseline;
  display: flex;
}

.review {
  width: 22.25rem;
  background-color: var(--bg);
  cursor: pointer;
  margin: 15px;
  padding: 20px;
  font-family: Alata, sans-serif;
  display: none;
}

.profile-img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  overflow: hidden;
}

.profile-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.profile {
  align-items: center;
  display: flex;
}

.name-user {
  flex-direction: column;
  display: flex;
}

.name-user strong {
  color: var(--text);
  letter-spacing: .5px;
  font-size: 1.1rem;
}

.name-user span {
  color: var(--text);
  font-size: .8rem;
}

.reviews svg {
  fill: var(--brandcolor3);
}

.box-top {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  display: flex;
}

.client-comment p {
  min-height: 100%;
  color: var(--text);
  font-size: .8rem;
}

.testimonial-box:hover {
  transition: all .3s;
  transform: translateY(-10px);
}

#loadMore {
  width: 110px;
  color: var(--text-light);
  text-align: center;
  background-color: var(--brandcolor3);
  border: 1px solid #0000;
  border-radius: 10px;
  margin: 20px auto;
  padding: 10px;
  transition: all .3s;
  display: block;
}

#loadMore:hover {
  color: var(--brandcolor2);
  border: 1px solid var(--brandcolor2);
  background-color: #fff;
  text-decoration: none;
}

.noContent {
  pointer-events: none;
  color: var(--text) !important;
  background-color: #0000 !important;
}

@media (max-width: 1060px) {
  .testimonial-box {
    width: 45%;
    padding: 10px;
  }
}

@media (max-width: 790px) {
  .testimonial-box {
    width: 100%;
  }

  .testimonial-heading h1 {
    font-size: 1.4rem;
  }
}

@media (max-width: 340px) {
  .box-top {
    flex-wrap: wrap;
    margin-bottom: 10px;
  }

  .reviews {
    margin-top: 10px;
  }
}

::selection {
  color: #fff;
  background-color: #252525;
}

.process {
  padding-left: 20px;
  padding-right: 20px;
}

.post-body {
  font-size: 16px;
  line-height: 1.6;
}

.lazy-youtube {
  cursor: pointer;
  background-color: #414141;
  padding-top: 56.3%;
  position: relative;
  overflow: hidden;
}

.lazy-youtube img {
  width: 100%;
  opacity: .7;
  top: -16.84%;
  left: 0;
}

.lazy-youtube .play-button {
  width: 60px;
  height: 60px;
  z-index: 1;
  opacity: .9;
  border: 2px solid #fffc;
  border-radius: 50px;
}

.lazy-youtube .play-button:hover {
  border-color: #ea6d23;
}

.lazy-youtube .play-button:hover:before {
  border-color: #0000 #0000 #0000 #00cc1b;
}

.lazy-youtube .play-button:before {
  content: "";
  border: 10px solid #0000;
  border-left: 15px solid #fffc;
  border-right-width: 0;
  border-radius: 3px;
  margin-left: 1px;
}

.lazy-youtube img, .lazy-youtube .play-button {
  cursor: pointer;
}

.lazy-youtube img, .lazy-youtube iframe, .lazy-youtube .play-button, .lazy-youtube .play-button:before {
  position: absolute !important;
}

.lazy-youtube .play-button, .lazy-youtube .play-button:before {
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.lazy-youtube iframe {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

label[for] {
  cursor: pointer;
}

.grid {
  width: 100%;
  position: relative;
}

.lightbox {
  width: 100%;
  z-index: 1;
  min-height: 100%;
  justify-content: center;
  align-items: center;
  transition: transform .5s ease-out;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
  transform: scale(0);
}

.lightbox img {
  max-width: 96%;
  max-height: 96%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 2px 8px #00000040;
}

input[type="checkbox"]:checked + .lightbox {
  transform: scale(1);
}

input[type="checkbox"]:checked ~ .grid {
  opacity: .125;
}

.grid {
  height: 100%;
  z-index: 0;
  text-align: center;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: flex-start;
  padding: 44px 15px 0;
  transition: opacity .75s;
  display: flex;
  overflow: auto;
}

.grid .grid-item {
  width: 20%;
  opacity: .95;
  padding: 5px;
  transition: opacity .5s;
  display: block;
}

.grid .grid-item:hover {
  opacity: 1;
}

@media screen and (max-width: 880px) {
  .grid .grid-item {
    width: 33.3333%;
  }
}

@media screen and (max-width: 480px) {
  .grid .grid-item {
    width: 50%;
  }
}

.grid img {
  max-width: 100%;
  max-height: 100%;
  box-shadow: 0 2px 8px #00000040;
}

.footer {
  text-align: center;
  padding: 30px 0;
  font-family: Alata, sans-serif;
}

.footer .row {
  width: 100%;
  color: var(--text);
  padding: .1%;
  font-size: .8em;
}

.footer .row svg {
  fill: var(--inverted);
}

.footer .row a {
  color: var(--text);
  text-decoration: none;
  transition: all .5s;
}

.footer .row a:hover {
  color: var(--text);
}

.footer .row ul {
  width: 100%;
}

.footer .row ul li {
  margin: 0 30px;
  display: inline-block;
}

.footer .row a i {
  margin: 0% 1%;
  font-size: 2em;
}

@media (max-width: 720px) {
  .footer {
    text-align: left;
    padding: 5%;
  }

  .footer .row ul li {
    text-align: left;
    margin: 10px 0;
    display: block;
  }

  .footer .row a i {
    margin: 0% 3%;
  }
}

/*# sourceMappingURL=main.83a7a649.css.map */
